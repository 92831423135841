import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import io from 'socket.io-client';

import { FrontendEvent, KidAgeRange } from './EventTypes';

export const useEvents = (url = '/api/events', ioTopic = 'free-seats') => {
  const [data, setData] = useState<FrontendEvent[] | undefined>();
  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    axios
      .get<FrontendEvent[]>(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((err: AxiosError) => {
        setError(err.message);
      })
      .then(() => {
        setRefreshing(false);
      });
  }, [url]);

  useEffect(() => {
    const s = io(`/${ioTopic}`);

    s.on(
      ioTopic,
      (data: Record<number, { freeSeats: number; kids: KidAgeRange[] }>) => {
        setData(
          (v) =>
            v &&
            v.map((e) => ({
              ...e,
              freeSeats: data[e.id] === undefined ? 0 : data[e.id].freeSeats,
              kids: data[e.id] === undefined ? [] : data[e.id].kids,
            }))
        );
      }
    );

    return () => {
      s.close();
    };
  }, [ioTopic]);

  return { data, error, refreshing };
};
