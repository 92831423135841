import React from 'react';
import { Field } from 'react-final-form';
import { IMaskInput } from 'react-imask';

import { AgreeInput } from './AgreeInput';
import { EmailInput } from './EmailInput';
import { Input } from './Input';
import { KidAgeRange } from './EventTypes';
import { KidsInput } from './KidsInput';
import { PhoneInputWithRow } from './PhoneInputWithRow';

interface EventFormProps {
  hidePhone?: boolean;
  kids?: KidAgeRange[];
  online?: boolean;
  qrCodes?: boolean;
  teens?: boolean;
  values: any;
}

const homegroupOptions = [
  { label: 'Я посещаю домашнюю группу', value: 'yes' },
  { label: 'Я хочу посещать домашнюю группу', value: 'want' },
  { label: 'Не посещаю и в ближайшее время не планирую', value: 'no' },
];

export const EventForm: React.FC<EventFormProps> = ({
  children,
  hidePhone,
  kids,
  online,
  qrCodes = false,
  teens = false,
  values,
}) => (
  <>
    <Input name="lastName" label="Фамилия" />
    <Input name="firstName" label="Имя" />

    {!hidePhone && <PhoneInputWithRow />}

    <EmailInput qrCodes={qrCodes} />

    {kids && kids.length > 0 && (
      <Field name="kids">
        {({ input }) => (
          <KidsInput
            kids={kids}
            onChange={input.onChange}
            value={input.value}
          />
        )}
      </Field>
    )}

    {teens && (
      <div className="form-group row">
        <label htmlFor="bornAt" className="col-lg-2 col-form-label">
          Дата рождения
        </label>
        <div className="col-lg-4">
          <Field name="bornAt">
            {({ input }) => (
              <IMaskInput
                mask={Date}
                id="bornAt"
                className="form-control"
                placeholder="дд.мм.гггг"
                unmask={true} // true|false|'typed'
                onAccept={(value: string) => {
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        </div>
      </div>
    )}

    {children}

    {!online && (
      <Field name="firstTime" type="checkbox">
        {({ input }) => (
          <div className="mt-4 mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="firstTime"
                {...input}
              />
              <label className="custom-control-label " htmlFor="firstTime">
                Я первый раз приду в церковь «Слово жизни»
              </label>
            </div>
            <small className="form-text text-muted">
              поставьте галочку, если вы еще никогда не были в нашей церкви
            </small>
          </div>
        )}
      </Field>
    )}

    {!online && teens && (
      <div>
        <div>Законный представитель несовершеннолетнего</div>
        <div>
          <Input name="agent_name" label="ФИО" />
          <PhoneInputWithRow name="agent_phone" />
        </div>

        <Field name="agent_agree" type="checkbox">
          {({ input }) => (
            <div className="mt-4 mb-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="agent_agree"
                  {...input}
                />
                <label className="custom-control-label " htmlFor="agent_agree">
                  <p>
                    Я, {values.agent_name ? `${values.agent_name}, ` : null}
                    {values.agent_phone
                      ? `тел. ${values.agent_phone}, `
                      : null}{' '}
                    являясь законным представителем несовершеннолетнего,
                    на основании ФЗ 125 «О свободе совести и о религиозных
                    объединениях» ст. 3. настоящим даю свое согласие Местной
                    религиозной организации Библейский центр христиан веры
                    евангельской (пятидесятников) «Слово жизни», (далее —
                    Оператор) на участие моего ребенка в подростковых
                    богослужениях Местной религиозной организации Библейский
                    центр христиан веры евангельской «Слово жизни» (далее —
                    Оператор). Данное согласие на обработку автоматизированную,
                    а также без использования средств автоматизации персональных
                    данных, включая сбор, систематизацию, накопление, хранение,
                    уточнение (обновление, изменение), использование,
                    обезличивание, блокирование, уничтожение персональных
                    данных, а именно: фамилии, имени, телефона, e-mail, даты
                    рождения моего ребенка, и в т. ч. моего ФИО и телефона.
                    Данное согласие действует с момента его заполнения,
                    до момента получения Оператором письменного заявления
                    об отзыве настоящего согласия на обработку персональных
                    данных. Согласие может быть отозвано мной путем составления
                    заявления в письменной форме и подачи Оператору.
                  </p>
                  <p>
                    Доступ к обработке моих данных, для исполнения целей данного
                    согласия, могут осуществлять и третьи лица, соблюдая
                    требования законодательства РФ об обеспечении
                    конфиденциальности.
                  </p>{' '}
                </label>
              </div>
            </div>
          )}
        </Field>
      </div>
    )}

    {!online && !teens && (
      <div className="my-4">
        {homegroupOptions.map((o) => (
          <Field type="radio" name="homegroup" value={o.value}>
            {({ input }) => (
              <div className="custom-control custom-radio my-2">
                <input
                  className="custom-control-input"
                  id={`homegroup-${o.value}`}
                  type="radio"
                  {...input}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`homegroup-${o.value}`}
                >
                  {o.label}
                </label>
              </div>
            )}
          </Field>
        ))}
      </div>
    )}

    <AgreeInput />
  </>
);
