import React from 'react';
import { Field } from 'react-final-form';

interface CheckboxProps {
  className?: string;
  id?: string;
  label: string;
  name: string;
  value?: string | number;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  id,
  label,
  name,
  value,
}) => {
  return (
    <Field name={name} type="checkbox" value={value}>
      {({ input }) => (
        <div className={`custom-control custom-checkbox ${className || ''}`}>
          <input
            type="checkbox"
            className="custom-control-input"
            id={id || name}
            {...input}
          />
          <label className="custom-control-label " htmlFor={id || name}>
            {label}
          </label>
        </div>
      )}
    </Field>
  );
};
