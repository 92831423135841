import { useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { v4 } from 'uuid';

import { SubmitError, SubmitResult } from '../app/EventTypes';

export const useStaffSignup = () => {
  const [result, setResult] = useState<SubmitResult | undefined>();

  const [id, setId] = useState<string>(v4());

  const resetId = useCallback(() => {
    setId(v4());
  }, []);

  const reset = useCallback(() => {
    setResult(undefined);
    resetId();
  }, [resetId]);

  const submit = useCallback(
    (formData: Record<string, string>) => {
      setResult(undefined);
      axios
        .post<SubmitResult>('/api/staff', {
          ...formData,
          service: parseInt(formData.service),
          id,
        })
        .then((res) => {
          resetId();
          setResult(res.data);
        })
        .catch((err: AxiosError) => {
          setResult({
            error: SubmitError.HTTP,
            message: err.response?.data?.message || err.message,
          });
        });
    },
    [id, resetId]
  );

  return { reset, result, submit };
};
