import React from 'react';
import { Field } from 'react-final-form';

export const AgreeInput: React.FC = () => {
  return (
    <Field name="agree" type="checkbox">
      {({ input }) => (
        <div className="mt-4 mb-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="agree"
              {...input}
            />
            <label className="custom-control-label small" htmlFor="agree">
              Я выражаю свое согласие Местной религиозной организации Библейский
              центр христиан веры евангельской (пятидесятников) «Слово жизни»,
              (далее — Оператор), адрес: 129278, г. Москва, ул. Павла Корчагина,
              дом 2А, на обработку моих персональных данных: фамилия, имя,
              телефон, e-mail; в целях регистрации на богослужение. Действия
              с персональными данными, на совершение которых дается согласие,
              как с использованием средств автоматизации, так и без таковых,
              в т.ч. третьими лицами в соответствии с действующим
              законодательством Российской Федерации и внутренними положениями
              Оператора: сбор, запись, систематизация, накопление,
              использование, хранение, уточнение, удаление, уничтожение. Данное
              согласие действует с момента его подписания до момента получения
              Оператором письменного заявления об отзыве настоящего согласия
              на обработку персональных данных. Согласие может быть отозвано
              мной путем составления заявления в письменной форме и подачи
              Оператору.
            </label>
          </div>
        </div>
      )}
    </Field>
  );
};
