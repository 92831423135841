import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import { buttonDate, dateDDMMM } from '../utils/format';
import { ErrorAlert } from './ErrorAlert';
import { EventForm } from './EventForm';
import { EventSelect } from './EventSelect';
import { formatErrors } from './formatErrors';
import { FrontendEvent, SubmitError } from './EventTypes';
import { useSignup } from './useSignup';
import { validate } from './validate';

interface SignupProps {
  events: FrontendEvent[];
}

export const Signup: React.FC<SignupProps> = ({ events }) => {
  const [eventId, setEventId] = useState<number | undefined>(
    events.find((e) => e.freeSeats > 0)?.id
  );

  const selected = useMemo(
    () => events.find((i) => i.id === eventId && i.freeSeats > 0),
    [events, eventId]
  );

  const totalFreeSeats = useMemo(
    () => events.reduce((sum, e) => sum + e.freeSeats, 0),
    [events]
  );

  const { reset, result, submit } = useSignup(selected?.id);

  if (result && result.error === SubmitError.Success) {
    return (
      <div style={{ marginBottom: 150 }}>
        <div className="alert alert-success">
          <p>
            <strong>Спасибо!</strong> Ждем вас в церкви. Не забудьте о мерах
            предосторожности.
          </p>
          {result.qrCodeSent && (
            <p>
              Для входа в здание церкви понадобится <strong>QR-код</strong>,
              который мы отправили вам на почту. Покажите его на экране телефона
              или распечатайте.
            </p>
          )}
          <p className="mb-0">
            Если не сможете прийти, пожалуйста, отмените регистрацию по ссылке
            из письма
            {result.qrCodeSent
              ? ''
              : ', отправленного вам на электронную почту'}
            . Тогда вместо вас сможет зарегистрироваться кто-то другой.
          </p>
        </div>
        <button
          className="btn btn-outline-secondary mr-2 mb-3"
          onClick={reset}
          type="button"
        >
          Зарегистрировать кого-то ещё
        </button>
        <a
          href="https://wolrus.org"
          className="btn btn-outline-secondary mr-2 mb-3"
        >
          Вернуться на сайт церкви
        </a>
      </div>
    );
  }

  const teens = selected?.title.indexOf('одростков') !== -1;

  return (
    <>
      <div
        className="alert alert-primary small"
        style={{ padding: '0.6rem 1rem' }}
      >
        If you need a simultaneous translation of the service into English,
        please register for Sunday at 1 pm.
      </div>

      <EventSelect events={events} onChange={setEventId} value={selected?.id} />

      {selected && (
        <div className="mt-2">
          <h4>
            {selected.title} {dateDDMMM(selected.startsAt)}
          </h4>
        </div>
      )}

      <div className="mt-4">
        <Form onSubmit={submit} validate={validate({ teens })}>
          {({ handleSubmit, errors, submitting, invalid, values }) => (
            <form onSubmit={handleSubmit}>
              <EventForm
                qrCodes={!!selected?.qrCodes}
                kids={selected?.kids || []}
                teens={teens}
                values={values}
              />

              <div className="form-group row">
                <div className="col-sm-12">
                  {(invalid || !selected) && (
                    <div className="text-muted">
                      {formatErrors(errors, selected, totalFreeSeats, result)}
                    </div>
                  )}

                  {totalFreeSeats > 0 && (
                    <div className="mt-3 mb-2">
                      <button
                        className="btn btn-lg btn-primary"
                        disabled={submitting || invalid || !selected}
                        type="submit"
                      >
                        Зарегистрироваться
                        {selected && buttonDate(selected.startsAt)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
      <div style={{ minHeight: 150 }}>
        {result?.error === SubmitError.HTTP && (
          <ErrorAlert title="Ошибка:">{result.message}</ErrorAlert>
        )}
        {result?.error === SubmitError.NoFreeSeats && (
          <ErrorAlert>
            <strong>Кончились места</strong>
            <br />
            К сожалению, кто-то зарегистрировался прямо перед вами.
            {totalFreeSeats > 0 ? (
              <> Попробуйте выбрать другое время.</>
            ) : (
              <>
                {' '}
                Свободных мест не осталось. Попробуйте зайти позже, иногда места
                освобождаются.
              </>
            )}
          </ErrorAlert>
        )}
        {result?.error === SubmitError.TooLate && (
          <ErrorAlert>
            <strong>Регистрация закончилась</strong>
            <br />
            К сожалению, регистрация закрылась, пока вы заполняли форму.
            {events.length > 1 &&
              (totalFreeSeats > 0 ? (
                <> Попробуйте выбрать другое время.</>
              ) : (
                <>
                  {' '}
                  Свободных мест не осталось. Попробуйте зайти позже, иногда
                  места освобождаются.
                </>
              ))}
          </ErrorAlert>
        )}
      </div>
    </>
  );
};
