import React from 'react';

interface HeaderProps {
  title?: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => (
  <>
    <div className="float-right d-none d-md-block">
      <a href="https://wolrus.org" className="btn btn-outline-secondary">
        Вернуться на сайт
      </a>
    </div>
    <a href="https://wolrus.org">
      <img
        alt="Слово жизни Москва"
        className="mb-3 d-none d-md-inline-block"
        src="/logo.png"
        width={200}
      />
      <img
        alt="Слово жизни Москва"
        className="mb-3 d-md-none"
        src="/logo.png"
        width={150}
      />
    </a>
    {title && <h1 className="mb-3">{title}</h1>}
  </>
);
