import React, { useCallback, useMemo } from 'react';

import { FrontendEvent } from './EventTypes';
import { shortDate, wordAtNumber } from '../utils/format';

const isTeens = (e: FrontendEvent | undefined) =>
  e && e.title.indexOf('Подростковое') !== -1;

interface EventSelectProps {
  events: FrontendEvent[];
  onChange: (value: number) => void;
  value: number | undefined;
}

export const EventSelect: React.FC<EventSelectProps> = ({
  events,
  onChange,
  value,
}) => {
  const onEventClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id') || '');
      onChange(id);
    },
    [onChange]
  );

  const groups = useMemo(() => {
    const res: {
      title?: string | React.ReactNode;
      events: FrontendEvent[];
    }[] = [];
    if (events.length > 0) {
      res.push({
        title: <>Регистрация на бого&shy;слу&shy;жения</>,
        events: [],
      });
    }
    events.forEach((e, i) => {
      res[res.length - 1].events.push(e);
      if (e.groupTitle) {
        res[res.length - 1].title = e.groupTitle;
      }
      if (e.breakAfter && events.length - 1 > i) {
        res.push({ events: [] });
      }
    });

    return res;
  }, [events]);

  return (
    <>
      {groups.map((g, i) => (
        <React.Fragment key={i}>
          {g.title && (
            <h1 className={`mt-2 mb-${i === 0 ? 2 : 5}`}>{g.title}</h1>
          )}
          {i === 0 && (
            <div
              className={
                isTeens(g.events[0]) || isTeens(g.events[1]) ? 'mb-5' : 'mb-2'
              }
            >
              Выберите день и время:
            </div>
          )}
          <div
            className={`btn-group mb-3${
              g.events.length > 6 ? ' flex-wrap' : ''
            }`}
            style={{ alignItems: 'flex-end' }}
          >
            {g.events.map((i) => {
              const teens = isTeens(i);
              return (
                <div
                  key={i.id}
                  className={`event-btn-container${teens ? ' with-teens' : ''}`}
                >
                  {teens && <div className="teens-logo" />}
                  <button
                    data-id={i.id}
                    className={[
                      'btn',
                      i.id === value
                        ? `btn-secondary${teens ? ' btn-teens' : ''}`
                        : 'btn-outline-secondary',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                    disabled={i.freeSeats < 1}
                    onClick={onEventClick}
                    type="button"
                  >
                    {shortDate(i.startsAt)}
                  </button>
                  <div className={`free-seats text-muted text-center my-2`}>
                    {i.freeSeats > 0 ? (
                      <>
                        {i.freeSeats}
                        <br className="d-md-none" />{' '}
                        {wordAtNumber(i.freeSeats, 'место', 'места', 'мест')}
                      </>
                    ) : (
                      <>
                        мест
                        <br className="d-md-none" /> нет
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
