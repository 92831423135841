import React, { useState, useEffect } from 'react';

interface LargeSpinnerProps {
  delay?: number;
}

export const LargeSpinner: React.FC<LargeSpinnerProps> = ({ delay = 100 }) => {
  const [visible, setVisible] = useState(delay === 0);

  useEffect(() => {
    const t = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [delay]);

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: '20rem' }}
    >
      {visible && <div className="spinner-border text-primary" role="status" />}
    </div>
  );
};
