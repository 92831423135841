export const services: { id: number; title: string }[] = [
  { id: 1, title: 'Прославление' },
  { id: 2, title: 'Служба порядка' },
  { id: 3, title: 'Кидс' },
  { id: 4, title: 'Подростковое служение' },
  { id: 5, title: 'Медиа ' },
  { id: 6, title: 'Гостеприимство' },
  { id: 7, title: 'ООМ' },
  { id: 8, title: 'Пасторы/спикеры' },
  { id: 9, title: 'Молодежное служение/креатив' },
  { id: 10, title: 'ГДБ' },
  { id: 11, title: 'Другое' },
];
