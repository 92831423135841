export const reEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const validate =
  (
    { teens = true, staff = false }: { teens: boolean; staff?: boolean } = {
      teens: true,
    }
  ) =>
  (values: Record<string, string>) => {
    const errors: Record<string, string> = {};

    if (!values.lastName) {
      errors.lastName = 'фамилию';
    }

    if (!values.firstName) {
      errors.firstName = 'имя';
    }

    if (!values.email || !reEmail.test(values.email)) {
      errors.email = 'почту';
    }

    if (!values.phone || values.phone.replace(/[^\d]+/g, '').length < 11) {
      errors.phone = 'телефон';
    }

    if (teens) {
      if (!values.bornAt) {
        errors.bornAt = 'дату рождения';
      } else {
        const dp = values.bornAt.split('.');
        dp.reverse();
        const bornAt = new Date(dp.join('-'));
        if (!bornAt || Number.isNaN(bornAt.getTime())) {
          errors.bornAt = 'дату рождения';
        }
      }
    }

    if (!teens && !values.homegroup && !staff) {
      if (!values.bornAt) {
        errors.homegroup = 'информацию о посещении домашней группы';
      }
    }

    if (teens) {
      if (!values.agent_name || !values.agent_phone || !values.agent_agree) {
        errors.agent = 'данные законного представителя';
      }
    }

    if (!values.agree) {
      errors.agree = 'согласие на обработку персональных данных';
    }

    return errors;
  };
