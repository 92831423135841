import React from 'react';

export const Footer: React.FC = () => (
  <div className="small text-muted">
    <div className="mb-3">
      Местная религиозная организация Библейский центр христиан веры
      евангельской «Слово жизни» ОГРН 1037739249569, зарегистрирована в Минюсте
      РФ, бланк № 76 08941, учетный № 7711010455, дата выдачи 27.12.2010г.
    </div>
    <div className="mb-3">
      <a
        href="https://wolrus.org/_service/348059/download/id/493653/name/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0+%D0%B2+%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8+%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8+%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85+%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        «Политика в отношении обработки персональных данных»
      </a>
    </div>
  </div>
);
