import React, { useCallback } from 'react';

import { KidAgeRange } from './EventTypes';
import { wordAtNumber } from '../utils/format';

interface KidsInputProps {
  kids: KidAgeRange[];
  onChange: (value: (number | undefined)[] | undefined) => void;
  value: (number | undefined)[] | undefined;
}

export const KidsInput: React.FC<KidsInputProps> = ({
  kids,
  onChange,
  value,
}) => {
  const onAddClick = useCallback(() => {
    const nonEmpty = kids.find((k) => k.left > 0);
    if (nonEmpty) {
      onChange(value ? [...value, nonEmpty.id] : [nonEmpty.id]);
    }
  }, [kids, onChange, value]);

  const onAgeRangeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const row = parseInt(e.target.getAttribute('data-row') || '');
      const next = [...(value || [])];
      next[row] = parseInt(e.target.value);
      onChange(next);
    },
    [onChange, value]
  );

  const onRemoveClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const row = parseInt(e.currentTarget.getAttribute('data-row') || '');
      const next = [...(value || [])];
      next.splice(row, 1);
      onChange(next);
    },
    [onChange, value]
  );

  const canAdd = kids.some((r) => r.left > 0);

  return (
    <>
      <div className="form-group row">
        <label className="col-lg-2 col-form-label">Дети</label>
        {!value?.length ? (
          <div className="col-lg-10">
            <button
              className="btn btn-outline-secondary"
              disabled={!canAdd}
              onClick={onAddClick}
              type="button"
            >
              Добавить ребенка
            </button>
            {!canAdd && (
              <small className="form-text text-muted">
                Мест на детское служение не осталось
              </small>
            )}
          </div>
        ) : (
          <div className="col-lg-10 col-form-label">
            Добавьте каждого ребенка отдельной строкой
          </div>
        )}
      </div>

      {(value || []).map((v, i) => (
        <div key={i} className="form-group row">
          <label className="col-lg-2 col-form-label" htmlFor={`kid${i}`}>
            {i + 1}-й ребенок
          </label>
          <div className="col-lg-4 col-8">
            <select
              className="custom-select"
              data-row={i}
              id={`kid${i}`}
              onChange={onAgeRangeChange}
              value={v}
            >
              {kids.map((r) => (
                <option key={r.id} value={r.id} disabled={r.left === 0}>
                  {r.title} —{' '}
                  {r.left === 0
                    ? 'мест нет'
                    : `${r.left} ${wordAtNumber(
                        r.left,
                        'место',
                        'места',
                        'мест'
                      )}`}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-4">
            <button
              className="btn btn-sm btn-outline-danger mt-1"
              data-row={i}
              onClick={onRemoveClick}
              type="button"
            >
              Удалить
            </button>
          </div>
        </div>
      ))}

      {!!value?.length && (
        <div className="form-group row">
          <div className="col-lg-10 offset-lg-2">
            <button
              className="btn btn-outline-secondary"
              disabled={!canAdd}
              onClick={onAddClick}
              type="button"
            >
              Добавить ещё одного ребенка
            </button>
            {!canAdd && (
              <small className="form-text text-muted">
                Мест на детское служение не осталось
              </small>
            )}
          </div>
        </div>
      )}
    </>
  );
};
