import React from 'react';

import { ErrorAlert } from './ErrorAlert';
import { Footer } from './Footer';
import { Header } from './Header';
import { LargeSpinner } from './LargeSpinner';
import { Signup } from './Signup';
import { useEvents } from './useEvents';

export const Home: React.FC = () => {
  const events = useEvents();
  return (
    <div className="container container-main mt-4 mb-5">
      <Header title="" />

      {events.refreshing && <LargeSpinner />}
      {events.data && events.data.length > 0 && <Signup events={events.data} />}
      {events.data && events.data.length === 0 && (
        <div style={{ height: 150 }}>
          <h4 className="mb-3">Регистрация закрыта</h4>
          <a href="https://wolrus.org" className="btn btn-outline-secondary">
            Вернуться на сайт церкви
          </a>
        </div>
      )}
      <ErrorAlert title="Ошибка:">{events.error}</ErrorAlert>

      <Footer />
    </div>
  );
};
