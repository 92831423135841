import React, { useCallback, useState } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const format = (e: string) => {
  e = e.replace(/^[87]+/, '');
  for (
    var t = '+7 (###) ###-##-##', n = t.split(''), r = 0, a = 0, o = t.length;
    r < o;
    r++
  )
    '#' === t[r] && (n[r] = e[a++] || ' ');
  return n.join('');
};

const removeFormatting = (e: string) => {
  var t = '+7 (###) ###-##-##'.split('#').filter(function (e) {
      return '' !== e;
    }),
    n = '';
  if (-1 !== e.indexOf('(')) {
    var r = e.slice(0, e.indexOf('(')),
      a = r.replace('+', '').replace('7', '').replace(' ', '');
    e = '+7 ' + a + e.slice(e.indexOf('('));
  }
  for (var o = 0, i = 0, u = t.length; o <= u; o++) {
    var s = t[o] || '',
      c = o === u ? e.length : e.indexOf(s, i);
    if (-1 === c) {
      n = e;
      break;
    }
    n += e.substring(i, c);
    i = c + s.length;
  }
  return (n.match(/\d/g) || []).join('');
};

export const PhoneInput: React.FC<NumberFormatProps> = ({
  onBlur,
  onFocus,
  ...rest
}) => {
  const [hideMask, setHideMask] = useState(true);

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setHideMask(true);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setHideMask(false);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  return (
    <NumberFormat
      type="tel"
      allowEmptyFormatting={!hideMask}
      format={format}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      removeFormatting={removeFormatting}
      {...rest}
    />
  );
};
