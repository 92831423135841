const dow = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const dowLong = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];
const dowButton = [
  'воскресенье',
  'понедельник',
  'вторник',
  'среду',
  'четверг',
  'пятницу',
  'субботу',
];

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

const timeHHMM = (d: Date): string => {
  const h = d.getHours();
  const m = d.getMinutes();
  return `${h >= 10 ? h : `0${h}`}:${m >= 10 ? m : `0${m}`}`;
};

export const shortDate = (date: string): string => {
  const d = new Date(date);
  return `${dow[d.getDay()]} ${timeHHMM(d)}`;
};

export const buttonDate = (date: string): string => {
  const d = new Date(date);
  return ` на ${dowButton[d.getDay()]} в ${timeHHMM(d)}`;
};

export const fullDate = (date: string): string => {
  const d = new Date(date);
  return [
    dowLong[d.getDay()],
    d.getDate(),
    months[d.getMonth()],
    d.getFullYear(),
    'в',
    timeHHMM(d),
  ].join(' ');
};

export const longDate = (date: string, y = true): string => {
  const d = new Date(date);
  return [d.getDate(), months[d.getMonth()], d.getFullYear(), y && 'г.']
    .filter(Boolean)
    .join(' ');
};

export const dateDDMMM = (date: string): string => {
  const d = new Date(date);
  return [d.getDate(), months[d.getMonth()]].filter(Boolean).join(' ');
};

export const wordAtNumber = (n: number, w1: string, w2: string, w5: string) => {
  const n100 = n % 100;
  if (n100 >= 10 && n100 <= 20) {
    return w5;
  }
  const n10 = n % 10;
  if (n10 >= 2 && n10 <= 4) {
    return w2;
  }
  if (n10 === 1) {
    return w1;
  }
  return w5;
};
