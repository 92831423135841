import React from 'react';
import { Field } from 'react-final-form';

type InputProps = {
  cols?: number;
  help?: string;
  label: string;
  name: string;
} & React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({
  cols = 6,
  help,
  label,
  name,
  ...rest
}) => (
  <div className="form-group row">
    <label htmlFor={name} className="col-lg-2 col-form-label">
      {label}
    </label>
    <div className={`col-lg-${cols}`}>
      <Field name={name}>
        {({ input }) => (
          <input
            type="text"
            className="form-control"
            id={name}
            {...input}
            {...rest}
          />
        )}
      </Field>
      <small className="form-text text-muted">{help}</small>
    </div>
  </div>
);
