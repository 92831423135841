export interface KidAgeRange {
  id: number;
  title: string;
  left: number;
}

export interface FrontendEvent {
  id: number;
  title: string;
  startsAt: string;
  endsAt: string;
  freeSeats: number;
  qrCodes: boolean;
  breakAfter: boolean;
  groupTitle?: string;
  kids: KidAgeRange[];
  online: boolean;
}

export const emptyEvent: FrontendEvent = {
  id: 0,
  title: '',
  startsAt: '',
  endsAt: '',
  freeSeats: 1000,
  qrCodes: false,
  breakAfter: false,
  kids: [],
  online: false,
};

export enum SubmitError {
  HTTP = -1,
  Success = 0,
  NoFreeSeats = 1,
  TooLate = 2,
}

export interface SubmitResult {
  error: SubmitError;
  message: string;
  qrCodeSent?: boolean;
}
