import { useState, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { v4 } from 'uuid';

import { SubmitError, SubmitResult } from './EventTypes';

const getAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const useSignup = (eventId: number | undefined) => {
  const [result, setResult] = useState<SubmitResult | undefined>();

  const [id, setId] = useState<string>(v4());

  const resetId = useCallback(() => {
    setId(v4());
  }, []);

  const reset = useCallback(() => {
    setResult(undefined);
    resetId();
  }, [resetId]);

  const submit = useCallback(
    (formData: Record<string, string>) => {
      setResult(undefined);
      if (!eventId) {
        return;
      }
      if (formData.bornAt) {
        const dp = formData.bornAt.split('.');
        dp.reverse();
        const bornAt = new Date(dp.join('-'));
        formData.bornAt = bornAt.toJSON();
        const age = getAge(bornAt);
        if (age < 10 || age > 17) {
          setTimeout(() => {
            setResult({
              error: SubmitError.HTTP,
              message: `Это богослужение только для подростков. Зарегистрируйтесь, пожалуйста, на другое.`,
            });
          }, Math.random() * 200 + 400);
          return;
        }
      }
      axios
        .post<SubmitResult>('/api/submit', { ...formData, eventId, id })
        .then((res) => {
          resetId();
          setResult(res.data);
        })
        .catch((err: AxiosError) => {
          setResult({
            error: SubmitError.HTTP,
            message: err.response?.data?.message || err.message,
          });
        });
    },
    [eventId, id, resetId]
  );

  return { reset, result, submit };
};
