import React, { useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import axios, { AxiosError } from 'axios';

import { ErrorAlert } from './ErrorAlert';
import { Footer } from './Footer';
import { Header } from './Header';
import { LargeSpinner } from './LargeSpinner';

export const Cancel: React.FC<RouteComponentProps<{ key: string }>> = ({
  match,
}) => {
  const [cancelledBefore, setCancelledBefore] = useState<
    boolean | null | undefined
  >();
  const [cancelledNow, setCancelledNow] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [cancelError, setCancelError] = useState<string | undefined>();

  useEffect(() => {
    setCancelledBefore(undefined);
    setRefreshing(true);
    setError(undefined);

    axios
      .get<{ cancelled: boolean | null }>(`/api/cancel/${match.params.key}`)
      .then((res) => setCancelledBefore(res.data.cancelled))
      .catch((err: AxiosError) => setError(err.message))
      .then(() => setRefreshing(false));
  }, [match.params.key]);

  const cancel = useCallback(() => {
    if (cancelledBefore === false) {
      setCancelling(true);
      setCancelError(undefined);
      axios
        .post(`/api/cancel/${match.params.key}`)
        .then(() => setCancelledNow(true))
        .catch((err: AxiosError) => setCancelError(err.message))
        .then(() => setCancelling(false));
    }
  }, [cancelledBefore, match.params.key]);

  return (
    <div className="container container-main mt-4 mb-5">
      <Header title="Отмена регистрации" />

      {refreshing && <LargeSpinner />}
      {!refreshing && (
        <div style={{ height: '20rem' }}>
          <ErrorAlert title="Ошибка:">{error}</ErrorAlert>

          {cancelledBefore === true && (
            <div className="alert alert-secondary">
              Регистрация уже отменена
            </div>
          )}
          {cancelledBefore === null && (
            <div className="alert alert-secondary">
              Неверная ссылка отмены регистрации
            </div>
          )}
          {cancelledBefore === false && !cancelledNow && (
            <>
              <button
                className="btn btn-lg btn-outline-danger"
                disabled={cancelling}
                onClick={cancel}
                type="button"
              >
                Отменить регистрацию
              </button>

              <ErrorAlert title="Ошибка:">{cancelError}</ErrorAlert>
            </>
          )}

          {cancelledBefore === false && cancelledNow && (
            <>
              <div className="alert alert-success">Регистрация отменена</div>
            </>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};
