import React from 'react';

import { Input } from './Input';

interface EmailInputProps {
  qrCodes?: boolean;
}

export const EmailInput: React.FC<EmailInputProps> = ({ qrCodes }) => {
  return (
    <Input
      name="email"
      label="Эл. почта"
      cols={4}
      type="email"
      help={
        qrCodes
          ? 'Пришлем QR-код, необходимый для входа в здание церкви'
          : undefined
      }
    />
  );
};
