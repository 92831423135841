import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { v4 } from 'uuid';
import { ValidationErrors } from 'final-form';
import axios from 'axios';

import { Checkbox } from './Checkbox';
import { ErrorAlert } from '../app/ErrorAlert';
import { EventForm } from '../app/EventForm';
import { Footer } from '../app/Footer';
import { formatErrors } from '../app/formatErrors';
import { FrontendEvent } from '../app/EventTypes';
import { Header } from '../app/Header';
import { LargeSpinner } from '../app/LargeSpinner';
import { reEmail } from '../app/validate';
import { shortDate } from '../utils/format';
import { useEvents } from '../app/useEvents';

const REDIRECT_URL = 'https://www.youtube.com/user/wolrustv';

interface EasterFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  eventIds?: number[];
  agree?: boolean;
  firstTime?: boolean;
}

const validate = (values: EasterFormValues) => {
  const errors: ValidationErrors = {};
  if (!values.lastName) {
    errors.lastName = 'фамилию';
  }
  if (!values.firstName) {
    errors.firstName = 'имя';
  }
  if (!values.email || !reEmail.test(values.email)) {
    errors.email = 'почту';
  }
  if (!values.eventIds || values.eventIds.length === 0) {
    errors.eventIds = 'событие';
  }
  if (!values.agree) {
    errors.agree = 'согласие на обработку персональных данных';
  }
  return errors;
};

export const Easter: React.FC = () => {
  const ev = useEvents('/api/easter-events', 'free-seats-easter');

  const [id, setId] = useState(v4());
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const events = useMemo(() => {
    const res: FrontendEvent[][] = [];

    (ev.data || []).forEach((i) => {
      if (res.length > 0 && res[res.length - 1][0].startsAt === i.startsAt) {
        res[res.length - 1].push(i);
      } else {
        res.push([i]);
      }
    });

    return res;
  }, [ev.data]);

  const onSubmit = useCallback(
    async (data: EasterFormValues) => {
      try {
        await axios.post('/api/easter-events', { id, ...data });
        setSuccess(true);
        setModal(true);
      } catch (err: any) {
        return {
          FORM_ERROR: err.message,
        };
      }
    },
    [id]
  );

  const reset = useCallback(() => {
    setSuccess(false);
    setModal(false);
    setId(v4());
  }, []);

  const closeModal = useCallback(() => setModal(false), []);

  return (
    <div className="container container-main mt-4 mb-5">
      <Header title="" />
      <h1 className="mb-4 mt-5">Регистрация на Пасхальные события</h1>

      {ev.refreshing && <LargeSpinner />}
      <ErrorAlert title="Ошибка:">{ev.error}</ErrorAlert>

      {success && (
        <div style={{ marginBottom: 150 }}>
          <div className="alert alert-success">
            <strong>Спасибо!</strong> Ждем вас в церкви. Не забудьте о мерах
            предосторожности.
          </div>
          <button
            className="btn btn-outline-secondary mr-2 mb-3"
            onClick={reset}
            type="button"
          >
            Зарегистрировать кого-то ещё
          </button>
          <a
            href="https://wolrus.org"
            className="btn btn-outline-secondary mr-2 mb-3"
          >
            Вернуться на сайт церкви
          </a>
        </div>
      )}

      {!success && !ev.refreshing && !ev.error && (
        <Form onSubmit={onSubmit} validate={validate}>
          {({ errors, handleSubmit, invalid, submitErrors, submitting }) => (
            <form onSubmit={handleSubmit}>
              <EventForm hidePhone values={{}}>
                <div className="my-5">
                  {events.map((i) => (
                    <div key={i[0].id} className="my-4">
                      <div className="easter-event-title">{i[0].title}</div>
                      <div className="d-flex flex-row">
                        <div className="mr-3">{shortDate(i[0].startsAt)}</div>
                        {i.map((event) => (
                          <Checkbox
                            className="mr-3"
                            id={`eventId${event.id}`}
                            key={event.id}
                            label={event.online ? 'Онлайн' : 'Офлайн'}
                            name="eventIds"
                            value={event.id}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </EventForm>

              {invalid && (
                <div className="text-muted">
                  {formatErrors(errors, {}, 10000)}
                </div>
              )}

              <div className="mt-4 mb-5">
                <button
                  className="btn btn-lg btn-primary"
                  disabled={
                    submitting || (errors && Object.keys(errors).length > 0)
                  }
                  type="submit"
                >
                  Зарегистрироваться
                </button>
              </div>

              <ErrorAlert>{submitErrors?.FORM_ERROR}</ErrorAlert>
            </form>
          )}
        </Form>
      )}

      {modal && (
        <div className="modal-backdrop fade show" onClick={closeModal} />
      )}

      {modal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  position: 'relative',
                  height: 300,
                  overflow: 'hidden',
                  border: 'none',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: -7,
                    left: -2,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  <img
                    alt="Наш YouTube-канал"
                    className="img-fluid"
                    src="/youtube.png"
                  />
                </div>
                <button
                  aria-label="Close"
                  className="close"
                  onClick={closeModal}
                  style={{ color: '#fff', opacity: 0.9, textShadow: 'none' }}
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body text-center pb-5"
                style={{ marginTop: -40 }}
              >
                <h3>
                  Подпишитесь на наш канал,
                  <br />
                  чтобы не пропустить онлайн-трансляции!
                </h3>
                <a
                  href={REDIRECT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-danger"
                  style={{
                    backgroundColor: '#ca0413',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: 20,
                    letterSpacing: '0.1em',
                    marginTop: '0.25rem',
                  }}
                >
                  Подписаться
                </a>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};
