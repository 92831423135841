import React from 'react';

interface ErrorAlertProps {
  title?: string;
}

export const ErrorAlert: React.FC<
  ErrorAlertProps & React.HTMLProps<HTMLDivElement>
> = ({ children, className, title, ...props }) =>
  children ? (
    <div className={`alert alert-danger mb-5 ${className || ''}`} {...props}>
      {title && <strong>{title} </strong>}
      {children}
    </div>
  ) : null;
