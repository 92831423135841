import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Cancel } from './Cancel';
import { Easter } from '../easter/Easter';
import { Home } from './Home';
import { Staff } from '../staff/Staff';

export const App: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/cancel/:key" exact component={Cancel} />
    <Route path="/staff" exact component={Staff} />
    <Route path="/easter" exact component={Easter} />
    <Redirect to="/" />
  </Switch>
);
