import React from 'react';
import { Field } from 'react-final-form';

import { PhoneInput } from './PhoneInput';

interface PhoneInputWithRowProps {
  name?: string;
}

export const PhoneInputWithRow: React.FC<PhoneInputWithRowProps> = ({
  name = 'phone',
}) => (
  <div className="form-group row">
    <label htmlFor={`tel-${name}`} className="col-lg-2 col-form-label">
      Телефон
    </label>
    <div className={`col-lg-4`}>
      <Field name={name}>
        {({ input }) => (
          <PhoneInput
            className="form-control"
            {...input}
            type="tel"
            id={`tel-${name}`}
          />
        )}
      </Field>
    </div>
  </div>
);
