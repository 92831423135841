import React from 'react';
import { Field, Form } from 'react-final-form';

import { AgreeInput } from '../app/AgreeInput';
import { EmailInput } from '../app/EmailInput';
import { ErrorAlert } from '../app/ErrorAlert';
import { emptyEvent, SubmitError } from '../app/EventTypes';
import { formatErrors } from '../app/formatErrors';
import { Header } from '../app/Header';
import { Input } from '../app/Input';
import { PhoneInputWithRow } from '../app/PhoneInputWithRow';
import { services } from './services';
import { useStaffSignup } from './useStaffSignup';
import { validateStaff } from './validateStaff';

export const Staff: React.FC = () => {
  const { reset, result, submit } = useStaffSignup();

  return (
    <div className="container container-main mt-4 mb-5">
      <Header title="Регистрация служителей" />

      {result && result.error === SubmitError.Success ? (
        <div style={{ marginBottom: 150 }}>
          <div className="alert alert-success">
            <strong>Готово!</strong> Ждем вас на богослужении. Не забудьте
            о мерах предосторожности.
          </div>
          <button
            className="btn btn-outline-secondary mr-2 mb-3"
            onClick={reset}
            type="button"
          >
            Зарегистрировать кого-то ещё
          </button>
          <a
            href="https://wolrus.org"
            className="btn btn-outline-secondary mr-2 mb-3"
          >
            Вернуться на сайт церкви
          </a>
        </div>
      ) : (
        <Form onSubmit={submit} validate={validateStaff}>
          {({ handleSubmit, submitting, invalid, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <div className="col-lg-10">
                  <div className="custom-control custom-checkbox mt-2">
                    <Field name="saturday" type="checkbox">
                      {({ input }) => (
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkSaturday"
                          {...input}
                        />
                      )}
                    </Field>
                    <label
                      className="custom-control-label"
                      htmlFor="checkSaturday"
                    >
                      Суббота
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox mt-2">
                    <Field name="sunday" type="checkbox">
                      {({ input }) => (
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkSunday"
                          {...input}
                        />
                      )}
                    </Field>
                    <label
                      className="custom-control-label"
                      htmlFor="checkSunday"
                    >
                      Воскресенье
                    </label>
                  </div>
                </div>
              </div>

              <Input name="lastName" label="Фамилия" />
              <Input name="firstName" label="Имя" />
              <PhoneInputWithRow />
              <EmailInput />

              <div className="form-group row">
                <label className="col-lg-2 col-form-label">Служение</label>
                <div className="col-lg-6">
                  <Field name="service">
                    {({ input }) => (
                      <select className="custom-select" {...input}>
                        <option></option>
                        {services.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.title}
                          </option>
                        ))}
                      </select>
                    )}
                  </Field>
                </div>
              </div>

              <AgreeInput />

              {invalid && (
                <div className="text-muted">
                  {formatErrors(errors, emptyEvent, 1000, undefined)}
                </div>
              )}

              {result?.error === SubmitError.HTTP && (
                <ErrorAlert title="Ошибка:">{result.message}</ErrorAlert>
              )}
              <div className="mt-3 mb-2">
                <button
                  className="btn btn-lg btn-primary"
                  disabled={submitting || invalid}
                  type="submit"
                >
                  Зарегистрироваться
                </button>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
