import { ValidationErrors } from 'final-form';

import { ErrorAlert } from './ErrorAlert';
import { SubmitError, SubmitResult } from './EventTypes';

export const formatErrors = (
  errors: ValidationErrors,
  event: {} | undefined,
  totalFreeSeats: number,
  result?: SubmitResult
) => {
  if (totalFreeSeats === 0) {
    if (result?.error === SubmitError.TooLate) {
      return null;
    }
    return (
      <ErrorAlert>
        К сожалению, свободных мест не осталось. Попробуйте зайти позже, иногда
        места освобождаются.
      </ErrorAlert>
    );
  }

  const words = errors ? Object.values(errors) : [];

  const messages =
    words.slice(0, words.length - 1).join(', ') +
    (words.length > 1 ? ' и ' : '') +
    words[words.length - 1];

  return words.length > 0
    ? `${
        words.length === 1 && errors?.agree ? 'Дайте' : 'Заполните'
      } ${messages}${event ? '' : ' и выберите время'}`
    : `${event ? '' : 'Выберите время'}`;
};
