import { validate } from '../app/validate';

export const validateStaff = (values: Record<string, string>) => {
  let errors = validate({ teens: false, staff: true })(values);

  if (!values.service) {
    errors.service = 'служение';
  }

  if (!values.saturday && !values.sunday) {
    errors = { day: 'день', ...errors };
  }

  return errors;
};
